import { createI18n } from "vue-i18n";

const messages = {
  en: {
    logOut: 'Log Out',
    dashboard: "Home",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    project_name: "Tik.am",
    TO: "TO",
    search_results: "Search Results",
    search: "Search",
    PAGES: "PAGES",
    category: "Category",
    categories: "Categories",
    price_start_from: "Price From",
    UPCOMING: "UPCOMING",
    EVENTS: "EVENTS",
    NOTHING_FOUND: "NOTHING FOUND",
    place: "Place",
    address: "Address",
    dates: "Dates",
    select_an_event_date: "Please Select Event Date",
    DATE_NOT_SELECTED: "Date is not selected",
    Price: "Price",
    Row: "Row",
    Column: "Column",
    ArenaName: "Name",
    EVENT_COMING_SOON: "EVENT COMING SOON",
    EVENT_ENTRANCE_IS_FREE: "EVENT ENTRANCE IS FREE",
    contact_us: "Contact Us",
    total_price: "Total Price",
    continiue: "Continue",
    tickets: "Tickets",
    Checkout: "Checkout",
    Cancel:"Cancel",
    seats:"Seats",
    seat: "Seat",
    cardPayment:"Card payment",
    shippingTicket:"Shipping Ticket",
    choosePaymentMethod:"Choose Payment Method",
    Entrance:"Entrance",
    start_date:"Start Date",
    end_date:"End Date",
    sign_in_to:"Sign In to",
    new_here:"New Here?",
    create_an_account: "Create an Account",
    email:"Email",
    Password:"Password",
    forgot_password:"Forgot Password ?",
    already_have_an_account:"Already have an account?",
    first_name:"First Name",
    last_name:"Last Name",
    phone:"Phone",
    date_of_birth:"Date of Birth",
    confirm_password:"Confirm Password",
    i_agree:"I Agree",
    term_and_conditions:"Terms and conditions",
    submit:"Submit",
    please_wait:"Please wait",
    is_required:"This field is required",
    my_profile:"My Profile",
    avatar:"Profile Picture",
    save:"Save",
    description:"Description",
    bonus:"Bonus",
    your_bonuses: "Your bonuses: ",
    bonus_withdrawal:"Write number of bonuses you wish to use",
    total_price_w_bonus:"Total price with bonuses calculated in",
    bonus_is_availiable_for:"Bonus can be used only for Card Payments",
    my_orders:"My Orders",
    my_wishlist:"Wishlist",
    add_to_wishlist: "Add to Wishlist",
    event_name: "Event Name",
    billing: "Billing",
    seats_count:"Seats Count",
    entrance_count:"Entrance Count",
    date:"Date",
    status:"Status",
    entrance:"Entrance",
    payment_successful:"Payment Successful! Thank you for your Order",
    payment_declined:"Something went wrong with your payment.",
    successfuly_created_account:"You have successfully created new account!",
    ok:"Ok, got it!",
    enter_email_to_reset:"Enter your email to reset your password.",
    reset_link_sent:"Password reset email have been successfully sent!",
    enter_your_password_and_confirm:"Enter your new Password and Password Confirmation.",
    password_changed_successfuly:"Password has been changed successfully, you can now log in!",
    current_password:"Current Password",
    new_password:"New Password",
    updated:"Your changes Successfuly Updated",
    duration: "Duration",
    stars: "Stars"
  },
  ru: {
    sign_in_to:"Вход в",
    logOut: 'Выход',
    dashboard: "Главная",
    pages: "Страницы",
    profile: "Профиль",
    profileOverview: "Профиль",
    account: "Аккаунт",
    accountOverview: "Аккаунт",
    settings: "Настройки",
    authentication: "Аутентификация",
    signIn: "Вход",
    signUp: "Выход",
    passwordReset: "Восстановление Пароля",
    error404: "Ошибка 404",
    error500: "Ошибка 500",
    project_name: "Tik.am",
    TO: "ДО",
    search_results: "Результаты Поиска",
    search: "Поиск",
    PAGES: "Страницы",
    category: "Категория",
    categories: "Категории",
    price_start_from: "Цена От",
    UPCOMING: "Предстоящие",
    EVENTS: "МЕРОПРИЯТИЯ",
    NOTHING_FOUND: "НИЧЕГО НЕ НАЙДЕНО",
    place: "Место",
    address: "Адрес",
    dates: "Даты",
    select_an_event_date: "Пожалуйста, выберите дату мероприятия",
    DATE_NOT_SELECTED: "Дата не выбрана",
    Price: "Цена",
    Row: "Ряд",
    Column: "Столбец",
    ArenaName: "Название Арены",
    EVENT_COMING_SOON: "СОБЫТИЕ СКОРО",
    EVENT_ENTRANCE_IS_FREE: "ВХОД НА МЕРОПРИЯТИЕ БЕСПЛАТНЫЙ",
    contact_us: "Связаться с нами",
    total_price: "Итоговая цена",
    continiue: "Продолжить",
    tickets: "Билеты",
    Checkout: "Оплата",
    Cancel:"Отмена",
    seats:"Seats",
    seat:"seat",
    cardPayment:"Оплата Картой",
    shippingTicket:"Доставка Билета",
    choosePaymentMethod:"Выберите способ оплаты",
    Entrance:"Вход",
    start_date:"Начало",
    end_date:"Конец",
    new_here:"Новый пользователь?",
    create_an_account: "Создать аккаунт",
    email:"Эл. Почта",
    Password:"Пароль",
    forgot_password:"Забыли пароль?",
    already_have_an_account:"У вас уже есть аккаунт?",
    first_name:"Имя",
    last_name:"Фамилия",
    phone:"Телефон",
    date_of_birth:"Дата Рождения",
    confirm_password:"Подтвердить Пароль",
    i_agree:"Я Согласен с",
    term_and_conditions:"Правилами пользования",
    submit:"Отправить",
    please_wait:"Пожалуйста подождите",
    is_required:"Это обязательное поле",
    my_profile:"Мой Профиль",
    avatar:"Фотография",
    save:"Сохранить",
    description:"Комментарии",
    bonus:"Бонус",
    your_bonuses: "Ваши бонусы",
    bonus_withdrawal:"Пиши меньше своих бонусов",
    total_price_w_bonus:"Итог с расчетом бонусов",
    bonus_is_availiable_for:"Бонус доступен только для оплаты картой",
    my_orders:"Мои покупки",
    my_wishlist:"Wishlist",
    add_to_wishlist: "Add to Wishlist",
    event_name: "Название события",
    billing: "Цена",
    seats_count:"Кол-во мест",
    entrance_count:"Кол-во билетов",
    date:"Дата",
    status:"Статус",
    entrance:"Вход",
    payment_successful:"Платеж успешен. Спасибо",
    payment_declined:"Что-то пошно не так.",
    successfuly_created_account:"Вы успешно зарегистрировались!",
    ok:"Хорошо",
    enter_email_to_reset:"Введите Ваш электронный адрес, чтобы сбросить пароль. ",
    reset_link_sent:"Мы Вам отправили ссылку для восстановления пароля",
    enter_your_password_and_confirm:"Введите новый пароль и подтверждение пароля.",
    password_changed_successfuly:"Пароль успешно изменен, теперь вы можете войти! ",
    current_password:"Текущий пароль",
    new_password:"Новый Пароль",
    updated:"Все изменения обновлены!",
    duration: "Продолжительность",
    stars: "Звезды"
  },
  am: {
    logOut: 'Ելք',
    dashboard: "Գլխավոր",
    pages: "Էջեր",
    profile: "Անձնական Տվյալներ",
    profileOverview: "Անձնական Տվյալներ",
    account: "Հաշիվ",
    accountOverview: "Հաշիվ",
    settings: "Կարգավորումներ",
    authentication: "Նույնականացում",
    signIn: "Մուտք",
    signUp: "Ելք",
    passwordReset: "Գաղտնաբառի Վերականգնում",
    error404: "Սխալ 404",
    error500: "Սխալ 500",
    project_name: "Tik.am",
    TO: "ԱՌ",
    search_results: "Որոնման Արդյունքներ",
    search: "Որոնել",
    PAGES: "Էջեր",
    category: "Բաժին",
    categories: "Բաժիններ",
    price_start_from: "Գինը Սկսած",
    UPCOMING: "Շուտով",
    EVENTS: "Իրադարձություններ",
    NOTHING_FOUND: "Ոչինչ չի գտնվել",
    place: "Վայր",
    address: "Հասցե",
    dates: "Օրեր",
    select_an_event_date: "Խնդրում ենք ընտրել միջոցառման ամսաթիվ ԵՎ ժամ",
    DATE_NOT_SELECTED: "Ամսաթիվն ընտրված չէ",
    Price: "Գին",
    Row: "Կարգ",
    Column: "Տեղ",
    ArenaName: "Արենայի անվանումը",
    EVENT_COMING_SOON: "Շուտով",
    EVENT_ENTRANCE_IS_FREE: "ՄԻՋՈՑԱՌՄԱՆ ՄՈՒՏՔԸ ԱԶԱՏ Է",
    contact_us: "Կապ Մեզ Հետ",
    total_price: "Ընդհանուր Արժեքը",
    continiue: "Շարունակել",
    tickets: "Տոմսեր",
    Checkout: "Վճարել",
    Cancel:"Չեղարկել",
    seats:"Նստատեղեր",
    seat:"Նստատեղ",
    cardPayment:"Քարտով վճարում",
    shippingTicket:"Առաքման տոմս",
    choosePaymentMethod:"Ընտրեք ՎՃարման Եղանակը",
    Entrance:"Ընտրեք Մուտքավճարը",
    start_date:"Սկիզբ",
    end_date:"Վերջ",
    sign_in_to:"Մուտք գործել",
    new_here:"Նոր օգտատեր ե՞ք",
    create_an_account: "Ստեղծել հաշիվ",
    email:"Էլ. Հասցե",
    Password:"Գաղտնաբառ",
    forgot_password:"Մոռացել ե՞ք գաղտնաբառը",
    already_have_an_account:"Արդեն ունե՞ք հաշիվ ",
    first_name:"Անուն",
    last_name:"Ազգանուն",
    phone:"Հեռախոս",
    date_of_birth:"Ծննդյան ամսաթիվ",
    confirm_password:"Հաստատել գաղտնաբառը",
    i_agree:"Համաձայն եմ ",
    term_and_conditions:"Ընդհանուր դրույթներրին եւ պայմաններին",
    submit:"Առաջ",
    please_wait:"Խնդրում ենք սպասել",
    is_required:"Պարտադիր դաշտ",
    my_profile:"Իմ Հաշիվը",
    avatar:"Նկար",
    save:"Պահպանել",
    description:"Նկարագրություն",
    bonus:"Բոնուս",
    your_bonuses: "Ձեր բոնուսներն են։",
    bonus_withdrawal:"Գրեք ձեր բոնուսներից պակաս թիվ",
    total_price_w_bonus:"Ընդհանուր արժեքը բոնուսները հանելուց հետո",
    bonus_is_availiable_for:"Բոնուսը գործում է միայն օնլայն գնման ժամանակ",
    my_orders:"Գնման Պատմություն",
    my_wishlist:"Ցանկությունների ցուցակ",
    add_to_wishlist: "Ավելացնել ցանկությունների ցանկին",
    event_name: "Միջոցառման անվանումը",
    billing: "Ընդհանհուր Գին",
    seats_count:"Տեղերի քանակ",
    entrance_count:"Տոմսերի քանակ",
    date:"Ամսաթիվ",
    status:"Կարգավիճակ",
    entrance:"Մուտքավճար",
    payment_successful:"Վճարումը հաջողությամբ կատարվել է: Շնորհակալություն",
    payment_declined:"Տեղի է ունեցել Սխալ",
    successfuly_created_account:"Գրանցումը հաջողությամբ կատարվեց",
    ok:"Շատ լավ",
    enter_email_to_reset:"Գաղտնաբառը վերականգնելու համար մուտքագրեք ձեր էլ. Հասցեն",
    reset_link_sent:"Գաղտնաբառի վերականգման էլփոստը հաջողությամբ ուղարկվել է: ",
    enter_your_password_and_confirm:"Մուտքագրեք ձեր նոր գաղտնաբառը և գաղտնաբառի հաստատումը:",
    password_changed_successfuly:"Գաղտնաբառը հաջողությամբ փոխվել է, այժմ կարող եք մուտք գործել: ",
    current_password:"Ընթացիկ գաղտնաբառը",
    new_password:"Նոր Գաղտնաբառ",
    updated:"Բոլոր փոփոխություննեևրը թարմացված են։",
    duration: "Տևողություն",
    stars: "Աստղեր"
  },

};

const i18n = createI18n({
  legacy: false,
  locale: "am",
  globalInjection: true,
  messages,
});

export default i18n;
